/* eslint-disable @typescript-eslint/naming-convention */
declare let __IS_DEV__: boolean;
declare let __IS_STAGING__: boolean;
declare let __SENTRY_RELEASE__: string;
declare let __STRIPE_API_KEY__: string;

export const Config = {
  isDev: __IS_DEV__,
  isStaging: __IS_STAGING__,
  sentryRelease: __SENTRY_RELEASE__,
  stripeApiKey: __STRIPE_API_KEY__,
};
