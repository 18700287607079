import { Config } from "./config";

// Shim for library that checks for process.brower but doesn't actually need a module
try {
  window.process = window.process || {};
} catch (e) {}

// Heap
window.heap = window.heap || [];
heap.load = function (e, t) {
  (window.heap.appid = e), (window.heap.config = t = t || {});
  var r = document.createElement("script");
  (r.type = "text/javascript"),
    (r.async = !0),
    (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js");
  var a = document.getElementsByTagName("script")[0];
  a.parentNode.insertBefore(r, a);
  for (
    var n = function (e) {
        return function () {
          heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      },
      p = [
        "addEventProperties",
        "addUserProperties",
        "clearEventProperties",
        "identify",
        "resetIdentity",
        "removeEventProperty",
        "setEventProperties",
        "track",
        "unsetEventProperty",
      ],
      o = 0;
    o < p.length;
    o++
  )
    heap[p[o]] = n(p[o]);
};
heap.load(Config.isDev || Config.isStaging ? "1961210921" : "948124972");

// Chameleon
// Chameleon
!(function (d, w) {
  var t =
      "SOeAVlYm1Kff6u9J5AFDbaPsfTr9EOOBq2sZLM1LYalxB9-1KFOH1-CwwKM1tlygzuj0fF",
    c = "chmln",
    m = "identify alias track clear set show on off custom help _data".split(
      " "
    ),
    i = d.createElement("script");
  if ((w[c] || (w[c] = {}), !w[c].root)) {
    (w[c].accountToken = t),
      (w[c].location = w.location.href.toString()),
      (w[c].now = new Date());
    for (var s = 0; s < m.length; s++) {
      !(function () {
        var t = (w[c][m[s] + "_a"] = []);
        w[c][m[s]] = function () {
          t.push(arguments);
        };
      })();
    }
    (i.src = "https://fast.trychameleon.com/messo/" + t + "/messo.min.js"),
      (i.async = !0),
      d.head.appendChild(i);
  }
})(document, window);
